import raw from 'utils/raw';
import React from 'react';
import typograf from 'utils/typograf';
import { Element } from 'react-scroll';
import { shape, string } from 'prop-types';

import { Layout, SEO } from 'layout';
import {
  Hero, Projects, Segment, Skills, Team,
} from 'blocks';

const IndexPage = ({ pageContext: { locale } }) => (
  <Layout lang={locale}>
    <SEO lang={locale} />

    <Hero />

    {/* Skills */}
    <Element name="skills" id="skills">
      <Skills />
    </Element>

    <Element name="projects">
      <Segment>
        {raw(
          typograf(
            'Наши решения радуют и работают долго. Мы настроены на долгосрочные партнерские отношения, нежели на быструю наемную работу. Много думаем о бизнес-показателях наших проектов, часто получаем крутые отзывы',
          ),
        )}
      </Segment>

      {/* Projects */}
      <Projects />
    </Element>

    {/* Team */}
    <Element name="team">
      <Team />
    </Element>
  </Layout>
);

IndexPage.propTypes = {
  pageContext: shape({
    locale: string.isRequired,
  }).isRequired,
};

export default IndexPage;
